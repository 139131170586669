import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CategoryType, MarketBackendCategory } from '../common/category-model';
import { placeToMarketCategoryService, getMarketCategoriesService, adoptCategoryFromMarketService, AdobeCategoryFromMarket, MarketRequestParams, getPublicMarketCategoriesService, removeFromMarketCategoryService, getPublicCategoryFromMarketService, getPublicCategoryDetails } from '../services/marketAxiosServices';
import { RootState } from './store';


interface CategoriesType {
  allCategories: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "chosenLanguageRequest" | "description" | "rating" | "createdBy" | "marketAccessible" | "adopted" | "categoryTopic" | "cardsLength" | "categoryType">[];
  counts: number | null;
  pagesMarker: number;
  next: string | null;
  previous: string | null;
  loadingCategories: boolean;
  errorGetCategories: string | undefined;
  loadingPlaceToMarket: boolean;
  errorPlaceToMarketCategories: string | undefined;
  loadingAdobeFromMarket: boolean;
  errorAdoptedCategories: string | undefined;
}

export const initialState: CategoriesType = {
  allCategories: [],
  counts: null,
  pagesMarker: 0,
  next: "",
  previous: "",
  loadingCategories: false,
  errorGetCategories: undefined,
  loadingPlaceToMarket: false,
  errorPlaceToMarketCategories: "",
  loadingAdobeFromMarket: false,
  errorAdoptedCategories: undefined,
}

export const getMarketCategories = createAsyncThunk('market/getMarketCategories', async ({
  searchQuery, 
  mostDownload,
  lastUpdate,
  topRate,
  currentPage,
  chosenLanguageRequest,
  categoryTopicRequest
}: MarketRequestParams) => {
  let response;
  try {
    response = await getMarketCategoriesService({
      searchQuery, 
      mostDownload,
      lastUpdate,
      topRate,
      currentPage,
      chosenLanguageRequest,
      categoryTopicRequest
    })
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const getPublicMarketCategories = createAsyncThunk('market/getPublicMarketCategories', async ({
  searchQuery, 
  mostDownload,
  lastUpdate,
  topRate,
  currentPage,
  chosenLanguageRequest,
  categoryTopicRequest
}: MarketRequestParams) => {
  let response;
  try {
    response = await getPublicMarketCategoriesService({
      searchQuery, 
      mostDownload,
      lastUpdate,
      topRate,
      currentPage,
      chosenLanguageRequest,
      categoryTopicRequest
    })
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response.data
})


export const placeToMarketCategory = createAsyncThunk('market/placeToMarketCategory', async ({
  categoryId,
  categoryName,
  userId,
  description,
  createdBy,
  marketApprovement,
  chosenLanguageRequest,
  categoryType
}: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "description" | "createdBy" | "marketApprovement" | "chosenLanguageRequest" | "categoryType">) => {
  try {
    await placeToMarketCategoryService({ 
      categoryId, 
      categoryName, 
      userId, 
      description, 
      createdBy, 
      marketApprovement,
      chosenLanguageRequest,
      categoryType
    })
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const removeFromMarketCategory = createAsyncThunk('market/removeFromMarketCategory', async ({
  categoryId,
  categoryName,
  userId,
  description,
  createdBy,
  marketAccessible,
  marketApprovement,
  chosenLanguageRequest,
  categoryType
}: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "description" | "createdBy" | "marketAccessible" | "marketApprovement" | "chosenLanguageRequest" | "categoryType">) => {
  try {
    await removeFromMarketCategoryService({ 
      categoryId, 
      categoryName, 
      userId, 
      description, 
      createdBy, 
      marketAccessible,
      marketApprovement,
      chosenLanguageRequest,
      categoryType
    })
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const adobeCategoryFromMarket = createAsyncThunk('market/adobeCategoryFromMarket', async ({categoryId, userId}: AdobeCategoryFromMarket) => {
  try {
    await adoptCategoryFromMarketService({categoryId, userId})
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const getPublicCategoryFromMarket = createAsyncThunk('market/getPublicCategoryFromMarket', async ({categoryId}: getPublicCategoryDetails) => {
  let response
  try {
    response = await getPublicCategoryFromMarketService({categoryId})
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const marketCategoriesSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    cleanPlaceRemoveCategory: (state) => {
      state.errorPlaceToMarketCategories = undefined
    },
    cleanGetFromMarketError: (state) => {
      state.errorAdoptedCategories = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarketCategories.pending, (state: CategoriesType) => {
        state.errorGetCategories = undefined
        state.loadingCategories = true
      })
      .addCase(getMarketCategories.fulfilled, (state: CategoriesType, action) => {
        state.loadingCategories = false
        state.counts = action.payload.count
        state.next = action.payload.next
        const pages = Math.ceil(action.payload.count / 20)
        state.pagesMarker = action.payload.count / 20 < 0 ? 2 : pages
        state.previous = action.payload.previous
        const allCategories: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "chosenLanguageRequest" | "description" | "rating" | "createdBy" | "marketAccessible" | "categoryTopic" | "adopted" | "cardsLength" | "categoryType">[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action.payload.results.forEach((category: any) => {
          const categoryObject: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "chosenLanguageRequest" | "description" | "rating" | "createdBy" | "marketAccessible" | "categoryTopic" | "adopted" | "cardsLength" | "categoryType"> = {
            categoryName: category.name,
            categoryId: category.id,
            userId: category.user.id,
            description: category.description,
            rating: category.rating,
            createdBy: category.created_by,
            marketAccessible: category.market_accessible,
            adopted: category.adopted,
            cardsLength: category.cards_length,
            categoryType: category.type_of_cards,
            chosenLanguageRequest: category.language,
            categoryTopic: category.topic
          }
          allCategories.push(categoryObject)
        })
        state.allCategories = allCategories
      })
      .addCase(getMarketCategories.rejected, (state: CategoriesType, action) => {
        state.errorGetCategories = action.error.message
        state.loadingCategories = false
      })
    builder
      .addCase(getPublicMarketCategories.pending, (state: CategoriesType) => {
        state.errorGetCategories = undefined
        state.loadingCategories = true
      })
      .addCase(getPublicMarketCategories.fulfilled, (state: CategoriesType, action) => {
        state.loadingCategories = false
        state.counts = action.payload.count
        const pages = Math.ceil(action.payload.count / 20)
        state.pagesMarker = action.payload.count / 20 < 0 ? 2 : pages
        const allCategories: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "chosenLanguageRequest" | "description" | "rating" | "createdBy" | "marketAccessible" | "categoryTopic" | "adopted" | "cardsLength" | "categoryType">[] = []
        action.payload.results.forEach((category : MarketBackendCategory) => {
          const categoryObject: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "chosenLanguageRequest" | "description" | "rating" | "createdBy" | "marketAccessible" | "categoryTopic" | "adopted" | "cardsLength" | "categoryType"> = {
            categoryName: category.name,
            categoryId: category.id,
            userId: "public",
            description: category.description,
            rating: category.rating,
            createdBy: category.created_by,
            marketAccessible: category.market_accessible,
            adopted: category.adopted,
            cardsLength: category.cards_length,
            categoryType: category.type_of_cards,
            chosenLanguageRequest: category.language,
            categoryTopic: category.topic
          }
          allCategories.push(categoryObject)
        })
        state.allCategories = allCategories
      })
      .addCase(getPublicMarketCategories.rejected, (state: CategoriesType, action) => {
        state.errorGetCategories = action.error.message
        state.loadingCategories = false
      })
    builder
      .addCase(getPublicCategoryFromMarket.pending, (state: CategoriesType) => {
        state.errorGetCategories = undefined
        state.loadingCategories = true
      })
      .addCase(getPublicCategoryFromMarket.fulfilled, (state: CategoriesType, action) => {
        state.loadingCategories = false
        const allCategories: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "chosenLanguageRequest" | "description" | "rating" | "createdBy" | "marketAccessible" | "categoryTopic" | "adopted" | "cardsLength" | "categoryType">[] = []
        const categoryObject: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "chosenLanguageRequest" | "description" | "rating" | "createdBy" | "marketAccessible" | "categoryTopic" | "adopted" | "cardsLength" | "categoryType"> = {
          categoryName: action.payload.name,
          categoryId: action.payload.id,
          userId: "public",
          description: action.payload.description,
          rating: action.payload.rating,
          createdBy: action.payload.created_by,
          marketAccessible: action.payload.market_accessible,
          adopted: action.payload.adopted,
          cardsLength: action.payload.cards_length,
          categoryType: action.payload.type_of_cards,
          chosenLanguageRequest: action.payload.language,
          categoryTopic: action.payload.topic
        }
        allCategories.push(categoryObject)
        state.allCategories = allCategories
      })
      .addCase(getPublicCategoryFromMarket.rejected, (state: CategoriesType, action) => {
        state.errorGetCategories = action.error.message
        state.loadingCategories = false
      })
    builder
      .addCase(placeToMarketCategory.pending, (state: CategoriesType) => {
        state.errorPlaceToMarketCategories = ""
        state.loadingPlaceToMarket = true
      })
      .addCase(placeToMarketCategory.fulfilled, (state: CategoriesType) => {
        state.loadingPlaceToMarket = false

      })
      .addCase(placeToMarketCategory.rejected, (state: CategoriesType, action) => {
        state.loadingPlaceToMarket = false
        state.errorPlaceToMarketCategories = action.error.message
      })
    builder
      .addCase(removeFromMarketCategory.pending, (state: CategoriesType) => {
        state.errorPlaceToMarketCategories = ""
        state.loadingPlaceToMarket = true
      })
      .addCase(removeFromMarketCategory.fulfilled, (state: CategoriesType) => {
        state.loadingPlaceToMarket = false

      })
      .addCase(removeFromMarketCategory.rejected, (state: CategoriesType, action) => {
        state.loadingPlaceToMarket = false
        state.errorPlaceToMarketCategories = action.error.message
      })
    builder
      .addCase(adobeCategoryFromMarket.pending, (state: CategoriesType) => {
        state.errorAdoptedCategories = undefined
        state.loadingAdobeFromMarket = true
      })
      .addCase(adobeCategoryFromMarket.fulfilled, (state: CategoriesType) => {
        state.loadingAdobeFromMarket = false

      })
      .addCase(adobeCategoryFromMarket.rejected, (state: CategoriesType, action) => {
        state.loadingAdobeFromMarket = false
        state.errorAdoptedCategories = action.error.message
      })
  }
})


export const actions = marketCategoriesSlice.actions;

export const marketCategoriesSelector = (state: RootState) => state[marketCategoriesSlice.name];