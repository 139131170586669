import { Button, Collapse, Nav, Navbar, NavbarToggler, NavItem } from "reactstrap"
import { isAuthenticatedSelector } from "../redux/userSlice";
import { useAppDispatch, useAppSelector } from '../redux/store';
import { useEffect, useState } from "react";
import { actions } from '../redux/userSlice'
import { Link, useLocation } from 'react-router-dom';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Language {
  nativeName: string;
}

interface Langs {
  [key: string]: Language;
}


const langs: Langs = {
  en: { nativeName: 'EN' },
  bg: { nativeName: 'BG' }
};

function Navigation() {
  const {
    avatar,
    firstName,
    lastName
  } = useAppSelector(isAuthenticatedSelector)
  const [collapsed, setCollapsed] = useState(true);
  const { t, i18n } = useTranslation();
  const toggleNavbar = () => setCollapsed(!collapsed);

  const access = localStorage.getItem('access');
  const refresh = localStorage.getItem('refresh');
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location.pathname === "/login") {
      dispatch(actions.logout())
    }
  }, [location, dispatch])

  return (
    <div className="navigation-menu">
      <Navbar color="dark" dark className="navbar-expand-lg">
      <Link to="/" className="navbar-brand me-auto">
        {t('navigation.home')}
      </Link>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar className="navbar-expand-lg">
            <div className="nav-market-public">
              {!access && !refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/market-public" className="nav-link">
                {t('navigation.market')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-market-public">
              {!access && !refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/demo-videos" className="nav-link">
                {t('navigation.demoVideos')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-market-public">
              {!access && !refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/teachers" className="nav-link">
                {t('navigation.teachers')}
                </RouterNavLink>
              </NavItem>}
            </div>
            
            <div className="nav-my-categories">
              {access && refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/my-categories" className="nav-link">
                  {t('navigation.myCategories')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-my-categories">
              {access && refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/ai-cards" className="nav-link">
                  {t('navigation.aiCategories')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-market" onClick={toggleNavbar}>
              {access && refresh && <NavItem>
                <RouterNavLink to="/market" className="nav-link">
                  {t('navigation.market')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-my-categories">
              {access && refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/kids" className="nav-link">
                {t('navigation.kidsCategories')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-send-to-me-categories">
              {access && refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/send-to-me-categories" className="nav-link">
                  {t('navigation.sendToMe')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-user-groups">
              {access && refresh && <NavItem>
                <RouterNavLink to="/user-groups" className="nav-link">
                  {t('navigation.myUserGroups')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-user-groups">
              {access && refresh && <NavItem>
                <RouterNavLink to="/reports" className="nav-link">
                  {t('navigation.reports')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-market-public">
            {/* {!access && !refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/pricing" className="nav-link">
                {t('navigation.pricing')}
                </RouterNavLink>
              </NavItem>} */}
            {access && refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/subscriptions" className="nav-link">
                {t('navigation.subscription')}
                </RouterNavLink>
              </NavItem>}
            </div>
            
            <div className="nav-user-groups">
              {access && refresh && <NavItem>
                <RouterNavLink to="/demo-videos-private" className="nav-link">
                  {t('navigation.demoVideos')}
                </RouterNavLink>
              </NavItem>}
            </div>
          </Nav>
          <Nav navbar className="navbar-expand-lg ms-auto me-0">
          <div className="nav-login">
              {!access && !refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/login" className="nav-link">
                  {t('navigation.login')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="nav-signup">
              {!access && !refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink
                  to="/signup"
                  className="nav-link"
                >
                  {t('navigation.signup')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="d-flex align-items-center">
              {access && refresh &&
                <RouterNavLink to="/profile" className="nav-link" onClick={toggleNavbar}>
                  <img
                    alt="logo"
                    //className="avatar-image"
                    src={avatar}
                    style={{
                      height: 40,
                      width: 40,
                      borderRadius: 50,
                      objectFit: "cover"
                    }}
                  />
                </RouterNavLink>}
              {access && refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/profile" className="nav-link">
                  {firstName} {lastName}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="d-flex align-items-center">
              {access && refresh && <NavItem onClick={toggleNavbar}>
                <RouterNavLink to="/logout" className="nav-link">
                  {t('navigation.logout')}
                </RouterNavLink>
              </NavItem>}
            </div>
            <div className="d-flex align-items-center">
              {Object.keys(langs).map((lng) => (
                <NavItem key={lng} onClick={toggleNavbar}>
                <Button
                  className="languages-buttons"
                  key={lng} 
                  style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', 
                  marginRight: '10px',
                  padding: '0.175rem 0.75rem',
                  }} 
                  type="submit" 
                  onClick={() => i18n.changeLanguage(lng)}
                  >
                  {langs[lng].nativeName}
                </Button>
                </NavItem>
              ))}
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Navigation