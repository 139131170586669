import { BASE_URL } from "../common/constants";
import axios from "axios";
//import { CategoryType } from "../common/category-model";
import { withTokenCheck } from "./userAxiosServices"


export interface GetReportsType {
  userEmail: string;
  page: number;
}

const getMyReports = async ({userEmail, page}: GetReportsType) => {
  const response = axios.get(BASE_URL + `card/reports/`, {
    params: { userEmail: userEmail, page: page },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getMyReportsService = withTokenCheck(getMyReports)
