import { BASE_URL } from "../common/constants";
import axios, { AxiosResponse } from "axios";
import { CardText, GetCardTextSearchParams } from '../common/card-model';
import { withTokenCheck } from './userAxiosServices'

/// CARD TEXT SERVICES 

const getCardsTextTags = async (categoryId: string) => {
  const response = axios.get(BASE_URL + `card/card-text-tags/`, {
    params: { category_id: categoryId },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getCardsTextTagsService = withTokenCheck(getCardsTextTags)

const getCardsTextByCategoryPaginated = async (categoryId: string, currentPage: number, tags: string[], searchQuery: string) => {
  const response = axios.get(BASE_URL + `card/card-text-paginated/`, {
    params: {
      category_id: categoryId,
      page: currentPage,
      tags: tags.join(','),
      search_value: searchQuery
    },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getCardsTextByCategoryPaginatedService = withTokenCheck(getCardsTextByCategoryPaginated)

const getCardsTextByCategory = async (categoryId: string) => {
  const response = axios.get(BASE_URL + `card/card-text/`, {
    params: { category_id: categoryId },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getCardsTextByCategoryService = withTokenCheck(getCardsTextByCategory)

const getCardsTextWithFilters = async ({ categoryId, quantity, tags, type }: GetCardTextSearchParams) => {
  //const cleanTags = tags.map(tag => tag.slice(0, -2))
  const response = axios.get(BASE_URL + `card/card-text/`, {
    params: { category_id: categoryId, quantity: quantity, tags: tags.join(','), type: type },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getCardsTextWithFiltersService = withTokenCheck(getCardsTextWithFilters)

const getCardTextDetails = async (cardId: string) => {
  const response = axios.get(BASE_URL + `card/card-text/${cardId}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getCardTextDetailsService = withTokenCheck(getCardTextDetails)

const createCardText = async ({
  sideOne,
  sideTwo,
  tag,
  categoryId,
}: Pick<CardText, 'sideOne' | 'sideTwo' | 'tag' | 'categoryId'>) => {
  const body = {
    first_side: sideOne,
    second_side: sideTwo,
    category: categoryId,
    tag: tag

  }
  const response = axios.post(BASE_URL + 'card/card-text/', body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const createCardTextService = withTokenCheck(createCardText)

const editCardText = async ({
  sideOne,
  sideTwo,
  categoryId,
  tag,
  id
}: Pick<CardText, "sideOne" | "sideTwo" | "categoryId" | "id" | "tag">) => {
  const body = {
    first_side: sideOne,
    second_side: sideTwo,
    category: categoryId,
    tag: tag
  }
  const response = axios.put(BASE_URL + `card/card-text/${id}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const editCardTextService = withTokenCheck(editCardText)

const deleteCardText = async (cardId: string) => {
  const response = axios.delete(BASE_URL + `card/card-text/${cardId}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const deleteCardTextService = withTokenCheck(deleteCardText)

export interface CreateCardTextWithFileRequest {
  fileForCards: File | null;
  categoryId: string;
}

const createCardTextWithFile = async ({
  fileForCards,
  categoryId,
}: CreateCardTextWithFileRequest): Promise<AxiosResponse<any, any>> => {
  let response
  if (fileForCards) {
    const formData = new FormData();
    formData.append('file', fileForCards);
    formData.append('category_id', categoryId);
    try {
      response = axios.post(BASE_URL + 'card/card-text-multiple/', formData, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
          'content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  if (!response) {
    // If response is still undefined, create a placeholder response with required properties
    response = {
      data: undefined,
      status: 500, // You may want to adjust the status code based on your needs
      statusText: 'Internal Server Error',
      headers: {},
      config: {},
    };
  }
  return response as AxiosResponse<any, any>;
}

export const createCardTextWithFileService = withTokenCheck(createCardTextWithFile)