import { BASE_URL } from "../common/constants";
import axios from "axios";
import { CategoryType } from "../common/category-model";
import { withTokenCheck } from "./userAxiosServices"

const sendToFriendCategory = async ({
  categoryId,
  categoryName,
  receiversEmails,
  userId,
}: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "receiversEmails">) => {
  const body = {
    receivers_emails: receiversEmails,
    id: categoryId,
    name: categoryName,
    user: userId,
  }
  const response = axios.put(BASE_URL + `card/private/${categoryId}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const sendToFriendCategoryService = withTokenCheck(sendToFriendCategory)

const getSendToMeCategories = async (userEmail: string, currentPage: number) => {
  const response = axios.get(BASE_URL + `card/private/`, {
    params: { user_email: userEmail, page: currentPage },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getSendToMeCategoriesService = withTokenCheck(getSendToMeCategories)

export interface RequestParams {
  categoryId: string;
  userId: string;
}

const acceptSendToMeCategories = async ({categoryId, userId}: RequestParams) => {
  const body = {category_id: categoryId, user_id: userId}
  const response = axios.post(BASE_URL + `card/private/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });

  return response;
}

export const acceptSendToMeCategoriesService = withTokenCheck(acceptSendToMeCategories)

export interface CancelSendToMeCategoryRequestParams {
  categoryId: string;
  userEmail: string;
  userIdCreator: string
}

const cancelSendToMeCategories = async ({categoryId, userEmail, userIdCreator}: CancelSendToMeCategoryRequestParams) => {
  const body = {category_id: categoryId, user_email: userEmail, user_id: userIdCreator}
  const response = axios.put(BASE_URL + `card/private-cancel/${categoryId}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });

  return response;
}

export const cancelSendToMeCategoriesService = withTokenCheck(cancelSendToMeCategories)

export interface UserSearchParams {
  search: string
  categoryId: string
}

const searchUsers = ({search, categoryId}: UserSearchParams) => {
  const response = axios.get(BASE_URL + 'search-users/', {
    params: {search: search, category_id: categoryId},
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const searchUsersService = withTokenCheck(searchUsers)