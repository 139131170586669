import { useState } from "react";


interface PreviewFlashCardProps {
  sideOne: string;
  sideTwo: string;
}

function PreviewFlashCard({ sideOne, sideTwo }: PreviewFlashCardProps) {
  const [side, setSide] = useState<boolean>();

  const theme = localStorage.getItem("theme")
  const frontThemeClass = theme === "light" ? "front-light-theme" : theme === "medium" ? "front-medium-theme" : "front-dark-theme";
  const backThemeClass = theme === "light" ? "back-light-theme" : theme === "medium" ? "back-medium-theme" : "back-dark-theme";

  function handleClick() {
    setSide(!side);
  }
  return (
    <div className={`flash-card ${side ? `side ${backThemeClass}` : frontThemeClass}`} onClick={handleClick}>
      <div className={`front ${frontThemeClass}`}>
        {sideOne}
      </div>
      <div className={`back ${backThemeClass}`}>
        {sideTwo}
      </div>
    </div>
  )
}

export default PreviewFlashCard