import { BASE_URL } from "../common/constants";
import axios, { AxiosResponse } from "axios";
import { withTokenCheck } from './userAxiosServices'
import { CardPictureCreateEdit, GetCardTextSearchParams } from "../common/card-model";


///  CARD PICTURE SERVICES

const getCardsPicturesTags = async (categoryId: string) => {
  const response = axios.get(BASE_URL + `card/card-images-tags/`, {
    params: { category_id: categoryId },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getCardsPicturesTagsService = withTokenCheck(getCardsPicturesTags)

const getCardsPictureByCategoryPaginated = async (categoryId: string, currentPage: number, tags: string[], searchQuery: string) => {
  const response = axios.get(BASE_URL + `card/card-picture-paginated/`, {
    params: { 
      category_id: categoryId, 
      page: currentPage, 
      tags: tags.join(','),
      search_value: searchQuery
    },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getCardsPictureByCategoryPaginatedService = withTokenCheck(getCardsPictureByCategoryPaginated)

const getCardsPicturesByCategory = async (categoryId: string) => {
  const response = axios.get(BASE_URL + `card/card-picture/`, {
    params: { category_id: categoryId },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getCardsPicturesByCategoryService = withTokenCheck(getCardsPicturesByCategory)

const getCardsPicturesWithFilters = async ({ categoryId, quantity, tags, type }: GetCardTextSearchParams) => {
  //const cleanTags = tags.map(tag => tag.slice(0, -2))
  const response = axios.get(BASE_URL + `card/card-picture/`, {
    params: { category_id: categoryId, quantity: quantity, tags: tags.join(','), type: type },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getCardsPicturesWithFiltersService = withTokenCheck(getCardsPicturesWithFilters)

const getCardsPicturesDetails = async (cardId: string) => {
  const response = axios.get(BASE_URL + `card/card-picture/${cardId}/`, {
    
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getCardsPicturesDetailsService = withTokenCheck(getCardsPicturesDetails)

const createCardPictures = async ({
  sideOne,
  sideTwo,
  categoryId,
  tag,
}: Pick<CardPictureCreateEdit, 'sideOne' | 'sideTwo' | 'tag' | 'categoryId'>): Promise<AxiosResponse<any, any>> => {
  let response
  if (sideOne && sideTwo && tag) {
    const formData = new FormData();
    formData.append('first_side', sideOne);
    formData.append('second_side', sideTwo);
    formData.append('category', categoryId);
    formData.append('tag', tag);
    try {
      response = axios.post(BASE_URL + 'card/card-picture/', formData, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
          'content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  if (!response) {
    // If response is still undefined, create a placeholder response with required properties
    response = {
      data: undefined,
      status: 500, // You may want to adjust the status code based on your needs
      statusText: 'Internal Server Error',
      headers: {},
      config: {},
    };
  }
  return response as AxiosResponse<any, any>;
}

export const createCardPicturesService = withTokenCheck(createCardPictures)

const editCardPictures = async ({
  sideOne,
  sideTwo,
  categoryId,
  tag,
  id
}: CardPictureCreateEdit): Promise<AxiosResponse<any, any>> => {
  let response
  if (sideTwo && tag && id && categoryId) {
    const formData = new FormData();
    formData.append('second_side', sideTwo);
    formData.append('category', categoryId);
    formData.append('tag', tag);
    formData.append('id', id);

    // Append 'first_side' to formData only if sideOne is not null
    if (sideOne !== null) {
      formData.append('first_side', sideOne);
    }
    try {
      console.log(formData);
      response = axios.put(BASE_URL + `card/card-picture/${id}/`, formData, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
        }
      });
    } catch (error) {
      console.log(error)
      throw error
    }
    if (!response) {
      // If response is still undefined, create a placeholder response with required properties
      response = {
        data: undefined,
        status: 500, // You may want to adjust the status code based on your needs
        statusText: 'Internal Server Error',
        headers: {},
        config: {},
      };
    }
  }
  return response as AxiosResponse<any, any>;
}

export const editCardPicturesService = withTokenCheck(editCardPictures);

const deleteCardPictures = async (cardId: string) => {
  const response = axios.delete(BASE_URL + `card/card-picture/${cardId}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const deleteCardPicturesService = withTokenCheck(deleteCardPictures)