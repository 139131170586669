import { BASE_URL } from "../common/constants";
import axios from "axios";
import { CardText } from '../common/card-model';
import { withTokenCheck } from "./userAxiosServices"

const guestCardText = async ({
  id,
  categoryId,
  sideOne,
  sideTwo,
  guessesScore
}: Pick<CardText, "id" | "categoryId" | "sideOne" | "sideTwo" | "guessesScore">) => {
  const body = {
    category: categoryId,
    first_side: sideOne,
    second_side: sideTwo,
    guesses_score: guessesScore,
  }
  const response = axios.put(BASE_URL + `card/card-text/${id}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const guestCardTextService = withTokenCheck(guestCardText)

const incorrectGuestCardText = async ({
  id,
  categoryId,
  sideOne,
  sideTwo,
  incorrectGuessesScore
}: Pick<CardText, "id" | "categoryId" | "sideOne" | "sideTwo" | "incorrectGuessesScore">) => {
  const body = {
    category: categoryId,
    first_side: sideOne,
    second_side: sideTwo,
    incorrect_guesses_score: incorrectGuessesScore,
  }
  const response = axios.put(BASE_URL + `card/card-text/${id}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const incorrectGuestCardTextService = withTokenCheck(incorrectGuestCardText)

const guestTypingCardText = async ({
  id,
  categoryId,
  sideOne,
  sideTwo,
  guessesScoreTyping
}: Pick<CardText, "id" | "categoryId" | "sideOne" | "sideTwo" | "guessesScoreTyping">) => {
  const body = {
    category: categoryId,
    first_side: sideOne,
    second_side: sideTwo,
    guesses_score_typing: guessesScoreTyping,
  }
  const response = axios.put(BASE_URL + `card/card-text/${id}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const guestTypingCardTextService = withTokenCheck(guestTypingCardText)

const incorrectGuestTypingCardText = async ({
  id,
  categoryId,
  sideOne,
  sideTwo,
  incorrectGuessesScoreTyping
}: Pick<CardText, "id" | "categoryId" | "sideOne" | "sideTwo" | "incorrectGuessesScoreTyping">) => {
  const body = {
    category: categoryId,
    first_side: sideOne,
    second_side: sideTwo,
    incorrect_guesses_score_typing: incorrectGuessesScoreTyping,
  }
  const response = axios.put(BASE_URL + `card/card-text/${id}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const incorrectGuestTypingCardTextService = withTokenCheck(incorrectGuestTypingCardText)


