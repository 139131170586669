import { BASE_URL } from "../common/constants";
import axios from "axios";
import { withTokenCheck } from "./userAxiosServices"
import { UserGroup } from "../common/user-groups";


const getMyUserGroupsPaginated = async (currentPage: number) => {
  const response = await axios.get(BASE_URL + `user-groups/list/paginated/`, {
    params: { page: currentPage },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getMyUserGroupsPaginatedService = withTokenCheck(getMyUserGroupsPaginated)

const getMyUserGroups = async (userId: string) => {
  const response = axios.get(BASE_URL + `user-groups/list/`, {
    params: { user_id: userId},
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getMyUserGroupsService = withTokenCheck(getMyUserGroups)

const getUserGroupDetails = async (userGroupId: string) => {
  const response = axios.get(BASE_URL + `user-groups/details/${userGroupId}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getUserGroupDetailsService = withTokenCheck(getUserGroupDetails)

const createUserGroups = async ({name, author}: Pick<UserGroup, "name" | "author">) => {
  const body = {
    name: name,
    author: author,
  }
  const response = axios.post(BASE_URL + `user-groups/list/paginated/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const createUserGroupsService = withTokenCheck(createUserGroups)

const editUserGroups = async ({
  id, 
  name, 
  author,
}: Pick<UserGroup, "name" | "author" | "id">) => {
  const body = {
    name: name,
    author: author,
  }
  const response = axios.put(BASE_URL + `user-groups/details/${id}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const editUserGroupsService = withTokenCheck(editUserGroups)

const deleteUserGroups = async (userGroupsId: string) => {
  const response = axios.delete(BASE_URL + `user-groups/details/${userGroupsId}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const deleteUserGroupsService = withTokenCheck(deleteUserGroups)

export interface UserSearchGroupsParams {
  search: string
  userGroupsId: string
}

const searchUsersGroups = ({search, userGroupsId}: UserSearchGroupsParams) => {
  const response = axios.get(BASE_URL + 'search-users-groups/', {
    params: {search: search, users_group_id: userGroupsId},
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const searchUsersGroupsService = withTokenCheck(searchUsersGroups)

const addUsersToUserGroup = async ({
  id,
  name,
  author,
  receiversEmails,
}: Pick<UserGroup, "name" | "author" | "id" | "receiversEmails">) => {
  const body = {
    user_emails: receiversEmails,
    id: id,
    name: name,
    author: author,
  }
  const response = axios.put(BASE_URL + `user-groups/details-add/${id}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const addUsersToUserGroupService = withTokenCheck(addUsersToUserGroup)

const removeUsersToUserGroup = async ({
  id,
  name,
  author,
  receiversEmails,
}: Pick<UserGroup, "name" | "author" | "id" | "receiversEmails">) => {
  const body = {
    user_emails: receiversEmails,
    id: id,
    name: name,
    author: author,
  }
  const response = axios.put(BASE_URL + `user-groups/details-remove/${id}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const removeUsersToUserGroupService = withTokenCheck(removeUsersToUserGroup)