import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch } from '../redux/store';
import {
  actions,
  
  loadUserDetail
} from '../redux/userSlice';

interface LayoutProps {
  children: JSX.Element;
}

export const Authenticated: React.FC<React.PropsWithChildren<LayoutProps>> = ({ children }) => {
  const dispatch = useAppDispatch();
  const access = localStorage.getItem('access');
  const refresh = localStorage.getItem('refresh');
  // const {
  //   endTrialDate,
  //   endSubscriptionDate
  // } = useAppSelector(isAuthenticatedSelector)


  const handleAuthentication = useCallback(async () => {
    try {
      await dispatch(loadUserDetail()).unwrap()
    } catch (error) {
      dispatch(actions.logout())
      console.log(error)
    }
  }, [dispatch])

  useEffect(() => {
    if (access && refresh) {
      handleAuthentication()
    }
  }, [handleAuthentication, access, refresh]);

  if (!access && !refresh) {
    return <Navigate to="/login" replace />;
  }

  // if ((endTrialDate && new Date(endTrialDate) < new Date()) ||
  //   (endSubscriptionDate && new Date(endSubscriptionDate) < new Date())) {
  //     return (
  //       <>
  //         {children}
  //         <Navigate to="/subscriptions" replace />;
  //       </>
  //     );
  // }

  return <>{children}</>;
};
