import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as en from './en/en.json';
import * as bg from './bg/bg.json';
import * as homeBg from './bg/homeBg.json';
import * as homeEn from './en/homeEn.json';

i18next
  .use(LanguageDetector)
  .use(initReactI18next).init({
  debug: false,
  resources: {
    en: {
      translation: en,
      home: homeEn,
    },
    bg: {
      translation: bg,
      home: homeBg,
    }
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});

export { i18next }; // Exporting i18n directly
