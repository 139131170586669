import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReportsBackend, ReportsType } from "../common/reports-types";
import { getMyReportsService, GetReportsType } from "../services/reportsAxiosServices";
import { RootState } from "./store";


interface ReportsSliceType {
    reportsList: ReportsType [];
    loadingReportsList: boolean;
    errorReportsList: string | undefined;
    counts: number;
    pages: number;
}

export const initialState: ReportsSliceType = {
    reportsList: [],
    loadingReportsList: false,
    errorReportsList: undefined,
    counts: 0,
    pages: 0
} 

export const getMyReports = createAsyncThunk('reports/getMyReports', async ({userEmail, page}: GetReportsType) => {
    let response;
    try {
        response = await getMyReportsService({userEmail, page})
    } catch(error) {
        console.error(error)
        throw error;
    }
    return response?.data
})

export const reportsSlice = createSlice({
    name: "reports",
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getMyReports.pending, (state: ReportsSliceType) => {
                state.errorReportsList = undefined
                state.loadingReportsList = true
            })
            .addCase(getMyReports.fulfilled, (state: ReportsSliceType, action) => {
                state.loadingReportsList = false
                state.counts = action.payload.count
                const pages = Math.ceil(action.payload.count / 20)
                state.pages = action.payload.count / 20 < 0 ? 2 : pages
                const allReports: ReportsType[] = []
                action.payload.results.forEach((category: ReportsBackend) => {
                    const reportsObject = {
                        categoryName: category.name,
                        categoryId: category.id,
                        user: {
                            userId: category.user.id,
                            firstName: category.user.first_name,
                            lastName: category.user.last_name,
                            avatarUrl: category.user.avatar_url,
                            email: category.user.email
                        },
                        createAt: category.date_created,
                        cardsLength: category.cards_len,
                        learnedCardsVerbalLength: category.learned_cards_verbal_len,
                        learnedCardsTypingLength: category.learned_cards_typing_len,
                        totalVerbalCorrectGuests: category.total_guesses_score,
                        totalVerbalInCorrectGuests: category.total_incorrect_guesses_score,
                        totalTypingGuests: category.total_guesses_score_typing,
                        totalTypingIncorrectGuests: category.total_incorrect_guesses_score_typing,
                        categoryType: category.type_of_cards,
                        learnedCardsVerbal: category.learned_cards_verbal_len,
                        learnedCardsTyping: category.learned_cards_typing_len,
                    }
                    allReports.push(reportsObject)
                })
                state.reportsList = allReports
            })
            .addCase(getMyReports.rejected, (state: ReportsSliceType, action) => {
                state.loadingReportsList = false
                state.errorReportsList = action.error.message
            })
    }
})

export const actions = reportsSlice.actions;

export const reportsSelector = (state: RootState) => state[reportsSlice.name];