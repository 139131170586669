import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetKidsCardsPaginatedRequest, KidsFlashCard, KidsFlashCardCreateEdit } from "../common/card-model";
import { createKidsCardService, deleteKidsCardService, editKidsCardService, getKidsCardsByCategoryPaginatedService, getKidsCardsByCategoryService, getKidsCardsDetailsService } from "../services/cardKidsAxiosServices";
import { RootState } from "./store";

interface KidsCardsStateType {
	allKidsCards: KidsFlashCard[];
	counts: number | null;
	pagesKidsCards: number;
	kidsFlashCardDetails: KidsFlashCard;
	loadingKidsFlashCardDetails: boolean;
	loadingKidsFlashCards: boolean;
	loadingCreateKidsFlashCard: boolean;
	loadingEditKidsFlashCard: boolean;
	loadingDeleteKidsFlashCard: boolean;
	deleteErrorKidsFlashCard: string | undefined;
	createErrorKidsFlashCard: string | undefined;
	editErrorKidsFlashCard: string | undefined;
	errorGetAllKidsCards: string | undefined;
	errorGetKidsCardsDetails: string | undefined;
	loginRequired: boolean;
}

const initialState: KidsCardsStateType = {
	allKidsCards: [],
	counts: null,
	pagesKidsCards: 0,
	kidsFlashCardDetails: {
		id: "",
		sideOne: "",
		sideTwo: "",
		categoryId: "",
		language: "",
		createdAt: "",
		updateAt: "",
		audio: "",
		listenCounts: 0
    },
	loadingKidsFlashCardDetails: false,
	loadingKidsFlashCards: false,
	loadingCreateKidsFlashCard: false,
	loadingEditKidsFlashCard: false,
	loadingDeleteKidsFlashCard: false,
	deleteErrorKidsFlashCard: undefined,
	createErrorKidsFlashCard: undefined,
	editErrorKidsFlashCard: undefined,
	errorGetAllKidsCards: undefined,
	errorGetKidsCardsDetails: undefined,
	loginRequired: false,
}

export const getKidsCardsByCategory = createAsyncThunk('cardPicture/getKidsCardsByCategory', async (
	categoryId: string
) => {
	let response
	try {
		response = await getKidsCardsByCategoryService(categoryId)
	} catch (error) {
		console.error(error)
		throw error
	}
	return response?.data
})

export const getKidsCardsByCategoryPaginated = createAsyncThunk('cardKids/getKidsCardsByCategoryPaginated', async ({
	categoryId,
	currentPage,
	searchQuery
}: GetKidsCardsPaginatedRequest) => {
	let response
	try {
		response = await getKidsCardsByCategoryPaginatedService(categoryId, currentPage, searchQuery)
	} catch (error) {
		console.error(error)
		throw error
	}
	return response?.data
})

export const createKidsCard = createAsyncThunk('cardKids/createKidsCard', async ({
	sideOne,
	sideTwo,
	language,
	categoryId
}: KidsFlashCardCreateEdit) => {
	let response;
	try {
		response = await createKidsCardService({ sideOne, sideTwo, language, categoryId })
	} catch (error) {
		console.error(error)
		throw error
	}
	return response?.data
})

export const deleteKidsCardById = createAsyncThunk('cardKids/deleteKidsCardById', async (cardId: string) => {
	try {
		await deleteKidsCardService(cardId)
	} catch (error) {
		console.error(error)
		throw error
	}
})

export const editKidsCardById = createAsyncThunk('cardKids/editKidsCardById', async ({
	sideOne,
	sideTwo,
	categoryId,
	language,
	id
}: KidsFlashCardCreateEdit) => {
	try {
		await editKidsCardService({ sideOne, sideTwo, categoryId, language, id })
	} catch (error) {
		console.error(error)
		throw error
	}
})

export const getKidsCardById = createAsyncThunk('cardKids/getKidsCardById', async (cardId: string) => {
	let response;
	try {
		response = await getKidsCardsDetailsService(cardId)
	} catch (error) {
		console.error(error)
		throw error
	}
	return response?.data
})

export const cardKidsSlice = createSlice({
	name: 'cardKids',
	initialState,
	reducers: {
		cleanCreateError: (state: KidsCardsStateType) => {
			state.createErrorKidsFlashCard = undefined
			state.errorGetKidsCardsDetails = undefined
			state.editErrorKidsFlashCard = undefined
		},
		cleanDeleteError: (state: KidsCardsStateType) => {
			state.deleteErrorKidsFlashCard = undefined
		},
		cleanGetKidsCardDetails: (state: KidsCardsStateType) => {
			state.errorGetKidsCardsDetails = undefined
		},
		cleanKidsFlashCardDetails: (state: KidsCardsStateType) => {
			state.kidsFlashCardDetails.categoryId = ""
			state.kidsFlashCardDetails.createdAt = ""
			state.kidsFlashCardDetails.id = ""
			state.kidsFlashCardDetails.sideOne = ""
			state.kidsFlashCardDetails.sideTwo = ""
			state.kidsFlashCardDetails.language = ""
			state.kidsFlashCardDetails.updateAt = ""
			state.kidsFlashCardDetails.listenCounts = 0
			state.kidsFlashCardDetails.audio = ""
		},
		cleanAllKidsCards: (state: KidsCardsStateType) => {
			state.allKidsCards = []
			state.counts = null
			state.pagesKidsCards = 0
		},

	},
	extraReducers: (builder) => {
			builder
				.addCase(getKidsCardsByCategory.pending, (state: KidsCardsStateType) => {
					state.errorGetAllKidsCards = undefined
					state.loadingKidsFlashCards = true
				})
				.addCase(getKidsCardsByCategory.fulfilled, (state: KidsCardsStateType, action) => {
					state.loadingKidsFlashCards = false
					const allKidsCards: KidsFlashCard[] = []
					action.payload.forEach((card: any) => {
						const cartPictureObject = {
							id: card.id,
							sideOne: card.first_side_url,
							sideTwo: card.second_side,
							categoryId: card.category,
							audio: card.audio_url,
							createdAt: card.created_at,
							updateAt: card.updated_at,
							language: card.language,
							listenCounts: card.listen_counts
						}
						allKidsCards.push(cartPictureObject)
					})
					state.allKidsCards = allKidsCards
				})
				.addCase(getKidsCardsByCategory.rejected, (state: KidsCardsStateType, action) => {
					state.loadingKidsFlashCards = false
					state.errorGetAllKidsCards = action.error.message
				}),
				builder
        .addCase(getKidsCardsByCategoryPaginated.pending, (state: KidsCardsStateType) => {
          state.errorGetAllKidsCards = undefined
          state.loadingKidsFlashCards = true
        })
        .addCase(getKidsCardsByCategoryPaginated.fulfilled, (state: KidsCardsStateType, action) => {
          state.loadingKidsFlashCards = false
          state.counts = action.payload.count
          const pages = Math.ceil(action.payload.count / 20)
          state.pagesKidsCards = action.payload.count / 20 < 0 ? 2 : pages
          const allKidsCards: KidsFlashCard[] = []
          action.payload.results.forEach((card: any) => {
            const kidsCard = {
              id: card.id,
              sideOne: card.first_side_url,
              sideTwo: card.second_side,
              categoryId: card.category,
              createdAt: card.created_at,
              updateAt: card.updated_at,
							audio: card.audio_url,
              language: card.language,
							listenCounts: card.listen_counts
            }
            allKidsCards.push(kidsCard)
          })
          state.allKidsCards = allKidsCards
        })
				.addCase(getKidsCardsByCategoryPaginated.rejected, (state: KidsCardsStateType, action) => {
          state.loadingKidsFlashCards = false
          state.errorGetAllKidsCards = action.error.message
        }),
				builder
        .addCase(createKidsCard.pending, (state: KidsCardsStateType) => {
          state.createErrorKidsFlashCard = undefined
          state.errorGetKidsCardsDetails = undefined
          state.editErrorKidsFlashCard = undefined
          state.loadingCreateKidsFlashCard = true
        })
        .addCase(createKidsCard.fulfilled, (state: KidsCardsStateType) => {
          state.loadingCreateKidsFlashCard = false
        })
        .addCase(createKidsCard.rejected, (state: KidsCardsStateType, action) => {
          state.loadingCreateKidsFlashCard = false
          state.createErrorKidsFlashCard = action.error.message
        }),
      builder
        .addCase(deleteKidsCardById.pending, (state: KidsCardsStateType) => {
          state.deleteErrorKidsFlashCard = ""
          state.loadingDeleteKidsFlashCard = true
        })
        .addCase(deleteKidsCardById.fulfilled, (state: KidsCardsStateType) => {
          state.loadingDeleteKidsFlashCard = false
        })
        .addCase(deleteKidsCardById.rejected, (state: KidsCardsStateType, action) => {
          state.loadingDeleteKidsFlashCard = false
          state.deleteErrorKidsFlashCard = action.error.message
        }),
      builder
        .addCase(getKidsCardById.pending, (state: KidsCardsStateType) => {
          state.errorGetKidsCardsDetails = undefined
          state.loadingKidsFlashCardDetails = true
        })
        .addCase(getKidsCardById.fulfilled, (state: KidsCardsStateType, action) => {
          state.loadingKidsFlashCardDetails = false
          state.kidsFlashCardDetails.id = action.payload.id
          state.kidsFlashCardDetails.sideOne = action.payload.first_side
          state.kidsFlashCardDetails.sideTwo = action.payload.second_side
          state.kidsFlashCardDetails.categoryId = action.payload.category
          state.kidsFlashCardDetails.language = action.payload.language
          state.kidsFlashCardDetails.createdAt = action.payload.created_at
          state.kidsFlashCardDetails.updateAt = action.payload.updated_at
          state.kidsFlashCardDetails.audio = action.payload.audio
          
        })
        .addCase(getKidsCardById.rejected, (state: KidsCardsStateType, action) => {
          state.loadingKidsFlashCardDetails = false
          state.errorGetKidsCardsDetails = action.error.message
        })
    builder
      .addCase(editKidsCardById.pending, (state: KidsCardsStateType) => {
        state.editErrorKidsFlashCard = undefined
        state.loadingEditKidsFlashCard = true
      })
      .addCase(editKidsCardById.fulfilled, (state: KidsCardsStateType) => {
        state.loadingEditKidsFlashCard = false
      })
      .addCase(editKidsCardById.rejected, (state: KidsCardsStateType, action) => {
        state.loadingEditKidsFlashCard = false
        state.editErrorKidsFlashCard = action.error.message
      })
	}
})

export const actions = cardKidsSlice.actions;

export const allKidsCardsSelector = (state: RootState) => state[cardKidsSlice.name];