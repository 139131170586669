import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

function CookiesModal() {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const checkConsent = useCallback(() => {
    // Check if user consent is saved in localStorage
    const userConsent = sessionStorage.getItem('cookiesConsent');
    if (!userConsent) {
      setIsModalVisible(true);
    }
  }, []);

  useEffect(() => {
    checkConsent();
  }, [checkConsent]);


  const handleHideModal = useCallback(() => {
    localStorage.setItem('cookiesConsent', 'true');
    setIsModalVisible(false);
  }, [])
  if(isModalVisible) {
    return (
      <div className="cookies-modal" data-testid="modal-blur">
        <div className="modal-inner-cookies" role="dialog" aria-label="Cookies modal">
          <div className="cookies-wrapper">
            <p className="cookies-heading">
              {t('cookies.header')} {t('cookies.content')} <Link to="/cookies-policy" target="_blank">Cookies</Link>
            </p>
            
          </div>
          <Button color="primary" onClick={handleHideModal}>{t('cookies.acceptBtn')}</Button>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default CookiesModal