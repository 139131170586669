import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { i18next } from './i18n/config';
import './App.css';
import { Authenticated } from './hoc/Authenticated';
import Navigation from './components/Navigation';
import ScrollToTop from './hooks/ScrollToTop';
import CookiesModal from './components/CookiesModal';
import Footer from './components/Footer';
import NotFound from './pages/404/NotFound';
import PreviewAiCards from './pages/flashCardsGenerate/PreviewAiCards';

// Lazy-loaded components
const Home = lazy(() => import('./pages/home/Home'));
const Login = lazy(() => import('./pages/login/Login'));
const Logout = lazy(() => import('./pages/logout/Logout'));
const Register = lazy(() => import('./pages/register/Register'));
const ResetPassword = lazy(() => import('./pages/resetPassword/ResetPassword'));
const ResetPassConfirm = lazy(() => import('./pages/resetPasswordConfirm/ResetPassConfirm'));
const Activate = lazy(() => import('./pages/register/Activate'));
const Profile = lazy(() => import('./pages/profile/Profile'));
const FlashCardTextWrapper = lazy(() => import('./pages/flashCards/FlashCardTextWrapper'));
const Cards = lazy(() => import('./pages/categoriesCards/Cards'));
const KidsCards = lazy(() => import('./pages/categoriesCards/KidsCards'));
const Categories = lazy(() => import('./pages/categories/Categories'));
const Market = lazy(() => import('./pages/market/Market'));
const SuccessRegister = lazy(() => import('./pages/register/SuccessRegister'));
const FlashCardPictureWrapper = lazy(() => import('./pages/flashCards/FlashCardPictureWrapper'));
const SendToMeCategories = lazy(() => import('./pages/sendToMeCategories/SendToMeCategories'));
const Subscription = lazy(() => import('./pages/subscriptions/Subscriptions'));
const SuccessPayment = lazy(() => import('./components/SuccessPayment'));
const FailedPayment = lazy(() => import('./components/FailedPayment'));
const Pricing = lazy(() => import('./pages/pricing/Pricing'));
const TermsOfUsage = lazy(() => import('./pages/termsAndPrivacy/TermsOfUsage'));
const PrivacyTerms = lazy(() => import('./pages/termsAndPrivacy/PrivacyTerms'));
const Cookies = lazy(() => import('./pages/termsAndPrivacy/Cookies'));
const UserGroups = lazy(() => import('./pages/userGroups/UserGroups'));
const DemoVideos = lazy(() => import('./pages/demoVideos/DemoVideos'));
const Reports = lazy(() => import('./pages/reports/Reports'));
const Teachers = lazy(() => import('./pages/landingPages/Teachers'));
const KidsCategories = lazy(() => import('./pages/kids/KidsCategories'));
const KidsFlashCardsWrapper = lazy(() => import('./pages/flashCards/KidsFlashCardsWrapper'));
const FlashCardGenerated = lazy(() => import('./pages/flashCardsGenerate/FlashCardGenerated'));

function App() {
  return (
    <>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <div className="app-wrapper">
            <Navigation />
            <ScrollToTop />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/subscriptions" element={<Authenticated><Subscription /></Authenticated>} />
                <Route path="/subscriptions-success" element={<Authenticated><SuccessPayment /></Authenticated>} />
                <Route path="/subscriptions-failed" element={<Authenticated><FailedPayment /></Authenticated>} />
                <Route path="/profile" element={<Authenticated><Profile /></Authenticated>} />
                <Route path="/my-cards/:categoryIdParam" element={<Authenticated><Cards /></Authenticated>} />
                <Route path="/my-kids-cards/:categoryIdParam" element={<Authenticated><KidsCards /></Authenticated>} />
                <Route path="/my-categories" element={<Authenticated><Categories /></Authenticated>} />
                <Route path="/market" element={<Authenticated><Market /></Authenticated>} />
                <Route path="/user-groups" element={<Authenticated><UserGroups /></Authenticated>} />
                <Route path="/market-public" element={<Market />} />
                <Route path="/market-public/:categoryId" element={<Market />} />
                <Route path="/send-to-me-categories" element={<Authenticated><SendToMeCategories /></Authenticated>} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Authenticated><Logout /></Authenticated>} />
                <Route path="/flash-cards-text/:categoryId" element={<Authenticated><FlashCardTextWrapper /></Authenticated>} />
                <Route path="/flash-cards-picture/:categoryId" element={<Authenticated><FlashCardPictureWrapper /></Authenticated>} />
                <Route path="/flash-cards-kids/:categoryId" element={<Authenticated><KidsFlashCardsWrapper /></Authenticated>} />
                <Route path="/signup" element={<Register />} />
                <Route path="/success-register" element={<SuccessRegister />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/password/reset/confirm/:uid/:token" element={<ResetPassConfirm />} />
                <Route path="/activation/:uid/:token" element={<Activate />} />
                <Route path="/terms-of-usage" element={<TermsOfUsage />} />
                <Route path="/privacy-policy" element={<PrivacyTerms />} />
                <Route path="/cookies-policy" element={<Cookies />} />
                <Route path="/demo-videos" element={<DemoVideos />} />
                <Route path="/demo-videos-private" element={<Authenticated><DemoVideos /></Authenticated>} />
                <Route path="/reports" element={<Authenticated><Reports /></Authenticated>} />
                <Route path="/ai-cards" element={<Authenticated><FlashCardGenerated /></Authenticated>} />
                <Route path="/ai-cards-preview" element={<Authenticated><PreviewAiCards /></Authenticated>} />
                <Route path="/kids" element={<Authenticated><KidsCategories /></Authenticated>} />
                <Route path="/teachers" element={<Teachers />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
          <Footer />
          <CookiesModal />
        </BrowserRouter>
      </I18nextProvider>
    </>
  );
}

export default App;


// import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import Home from './pages/home/Home';
// import './App.css'
// import Login from './pages/login/Login';
// import Logout from './pages/logout/Logout';
// import Register from './pages/register/Register';
// import ResetPassword from './pages/resetPassword/ResetPassword';
// import ResetPassConfirm from './pages/resetPasswordConfirm/ResetPassConfirm';
// import Activate from './pages/register/Activate';
// import { Authenticated } from './hoc/Authenticated';
// import Navigation from './components/Navigation';
// import Profile from './pages/profile/Profile';
// import FlashCardTextWrapper from './pages/flashCards/FlashCardTextWrapper';
// import Cards from './pages/categoriesCards/Cards';
// import Categories from './pages/categories/Categories';
// import Market from './pages/market/Market';
// import SuccessRegister from './pages/register/SuccessRegister';
// import FlashCardPictureWrapper from './pages/flashCards/FlashCardPictureWrapper';
// import SendToMeCategories from './pages/sendToMeCategories/SendToMeCategories';
// import Subscription from './pages/subscriptions/Subscriptions';

// import './i18n/config';
// import SuccessPayment from './components/SuccessPayment';
// import FailedPayment from './components/FailedPayment';
// import Footer from './components/Footer';
// import Pricing from './pages/pricing/Pricing';
// import TermsOfUsage from './pages/termsAndPrivacy/TermsOfUsage';
// import PrivacyTerms from './pages/termsAndPrivacy/PrivacyTerms';
// import Cookies from './pages/termsAndPrivacy/Cookies';
// import ScrollToTop from './hooks/ScrollToTop'
// import CookiesModal from './components/CookiesModal';
// import NotFound from './pages/404/NotFound';
// import UserGroups from './pages/userGroups/UserGroups';
// import DemoVideos from './pages/demoVideos/DemoVideos';


// function App() {
  
//   return (
//     <>
//       <BrowserRouter>
//         <div className="app-wrapper">
//           <Navigation />
//           <ScrollToTop />
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/pricing" element={<Pricing />} />
//             <Route path="/subscriptions" element={<Authenticated><Subscription /></Authenticated>} />
//             <Route path="/subscriptions-success" element={<Authenticated><SuccessPayment /></Authenticated>} />
//             <Route path="/subscriptions-failed" element={<Authenticated><FailedPayment /></Authenticated>} />
//             <Route path="/profile" element={<Authenticated><Profile /></Authenticated>} />
//             <Route path="/my-cards/:categoryIdParam" element={<Authenticated><Cards /></Authenticated>} />
//             <Route path="/my-categories" element={<Authenticated><Categories /></Authenticated>} />
//             <Route path="/market" element={<Authenticated><Market /></Authenticated>} />
//             <Route path="/user-groups" element={<Authenticated><UserGroups /></Authenticated>} />
//             <Route path="/market-public" element={<Market />} />
//             <Route path="/send-to-me-categories" element={<Authenticated><SendToMeCategories /></Authenticated>} />
//             <Route path="/login" element={<Login />} />
//             <Route path="/logout" element={<Authenticated><Logout /></Authenticated>} />
//             <Route path="/flash-cards-text/:categoryId" element={<Authenticated><FlashCardTextWrapper /></Authenticated>} />
//             <Route path="/flash-cards-picture/:categoryId" element={<Authenticated><FlashCardPictureWrapper /></Authenticated>} />
//             <Route path="/signup" element={<Register />} />
//             <Route path="/success-register" element={<SuccessRegister />} />
//             <Route path="/reset-password" element={<ResetPassword />} />
//             <Route path="/password/reset/confirm/:uid/:token" element={<ResetPassConfirm />} />
//             <Route path="/activation/:uid/:token" element={<Activate />} />
//             <Route path="/terms-of-usage" element={<TermsOfUsage />} />
//             <Route path="/privacy-policy" element={<PrivacyTerms />} />
//             <Route path="/cookies-policy" element={<Cookies />} />
//             <Route path="/demo-videos" element={<DemoVideos />} />
//             <Route path="/demo-videos-private" element={<Authenticated><DemoVideos /></Authenticated>} />
//             <Route path='*' element={<NotFound />} />
//           </Routes>
//         </div>
//         <Footer />
//         <CookiesModal />
//       </BrowserRouter>
//     </>
//   )
// }

// export default App
