import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { aiCreateCardTextBulk, allCardsTextSelector } from '../../redux/cardTextSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import PreviewFlashCard from '../flashCards/PreviewFlashCard';

function PreviewAiCards() {

  const { t } = useTranslation();
  const [categoryName, setCategoryName] = useState("")
  const formRef = useRef<HTMLFormElement | null>(null);

  const navigate = useNavigate();

  const {
    loadingAiCreateCardsText,
    errorCreateAiCardsText,
    generatedCardsText,
  } = useAppSelector(allCardsTextSelector)

  const dispatch = useAppDispatch();


  const handleChangeCategoryName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCategoryName(value)
  }, [])



  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      if (formRef.current && !formRef.current.reportValidity()) {
        return;
      }
      if(generatedCardsText[0].sideOne.length > 0 && categoryName) {
        const aiCreateCardsObject = {
          categoryName: categoryName,
          flashcards: generatedCardsText,
        }
        await dispatch(aiCreateCardTextBulk(aiCreateCardsObject)).unwrap()
        navigate('/ai-cards')
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div>
      <h1 className="text-center">{t("aiCardsPage.generateCards")}</h1>
      <div className="ai-create-cards-form">
        <Form innerRef={formRef} onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name">{t('aiCardsPage.generateCardsModal.topic')}</Label>
            <Col>
              <Input
                disabled={loadingAiCreateCardsText}
                id="topic"
                name="topic"
                value={categoryName}
                onChange={handleChangeCategoryName}
                placeholder={t('aiCardsPage.generateCardsModal.topic')}
                type="text"
                minLength={3}
                maxLength={40}
                required
              />
            </Col>
          </FormGroup>
          {!errorCreateAiCardsText && loadingAiCreateCardsText && (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )}
          {!loadingAiCreateCardsText && errorCreateAiCardsText === "Network Error" && (
            <p className="error-message" data-testid="error-message">
              {t('categoryPage.createCategoryModal.errorCreateCategory')}
            </p>
          )}
          {!loadingAiCreateCardsText && errorCreateAiCardsText !== "Network Error" && (
            <p className="error-message" data-testid="error-message">
              {errorCreateAiCardsText}
            </p>
          )}
          <Button
            disabled={loadingAiCreateCardsText}
            style={{ backgroundColor: "var(--blue)", border: "2px solid", borderColor: "var(--quarterly)" }}
            type="submit"
            value="Submit"
          >
            {t('aiCardsPage.btnSaveCards')}
          </Button>
        </Form>
      </div>
      <div className="preview-flash-cards">
        {generatedCardsText.map((card, index) => (
          <PreviewFlashCard sideOne={card.sideOne} sideTwo={card.sideTwo} key={index} />
        ))}

      </div>
    </div>
  )
}

export default PreviewAiCards