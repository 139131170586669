import { configureStore } from '@reduxjs/toolkit';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { userSlice } from './userSlice';
import { cardsTextSlice } from './cardTextSlice';
import { categoriesSlice } from './categoriesSlice';
import { marketCategoriesSlice } from './marketCategoriesSlice';
import { sendToUsersCategoriesSlice } from './sendToUsersCategorySlice';
import { cardsPicturesSlice } from './cardPicturesSlice';
import { userGroupsSlice } from './userGroupsSlice';
import { reportsSlice } from './reportsSlice';
import { cardKidsSlice } from './kidsCardSlice';


export const createStore = () => 
    configureStore({
        reducer: {
            [userSlice.name]: userSlice.reducer,
            [cardsTextSlice.name]: cardsTextSlice.reducer,
            [cardsPicturesSlice.name]: cardsPicturesSlice.reducer,
            [cardKidsSlice.name]: cardKidsSlice.reducer,
            [categoriesSlice.name]: categoriesSlice.reducer,
            [marketCategoriesSlice.name]: marketCategoriesSlice.reducer,
            [sendToUsersCategoriesSlice.name]: sendToUsersCategoriesSlice.reducer,
            [userGroupsSlice.name]: userGroupsSlice.reducer,
            [reportsSlice.name]: reportsSlice.reducer
        },
        
    })

export type StoreType = ReturnType<typeof createStore>;
export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>;
export const useAppDispatch = () => useDispatch<ReturnType<typeof createStore>['dispatch']>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;