import { PasswordConfirmType, User, activateUserAccountServiceType } from "../common/user-model";
import { BASE_URL } from "../common/constants";
import axios, { AxiosResponse } from "axios";

// const checkUserAccessTokenService = async (token: string) => {
//   const response = axios.post(BASE_URL + 'user/jwt/verify/', {
//     token
//   });
//   return response
// }

const checkUserRefreshTokenService = async (refresh: string) => {
  const response = axios.post(BASE_URL + 'user/jwt/refresh/', {
    refresh
  });
  return response
}

type RequestFunction = (...args: any[]) => Promise<AxiosResponse>;

export const withTokenCheck = (requestFunction: RequestFunction) => async (...args: Parameters<RequestFunction>) => {
    const access = localStorage.getItem('access');
    if (access) {
      const decodedToken = JSON.parse(atob(access.split('.')[1]));
      const expirationTime = decodedToken.exp * 1000; 
        if (Date.now() < expirationTime) {
        return await requestFunction(...args);
      }
    }
    const refresh = localStorage.getItem('refresh');
    if(refresh) {
      try {
        const refreshResponse = await checkUserRefreshTokenService(refresh);
        const newAccess = refreshResponse.data.access;
        const newRefresh = refreshResponse.data.refresh;
        localStorage.setItem('access', newAccess);
        localStorage.setItem('refresh', newRefresh);
        return await requestFunction(...args);
      } catch(error) {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
      }
    } else {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
    }
};

export const registerUserService = async ({
  firstName,
  lastName,
  email,
  password,
  rePassword
}: User) => {
  const response = axios.post(BASE_URL + 'user/users/', {
    first_name: firstName,
    last_name: lastName,
    email: email,
    password: password,
    re_password: rePassword
  })
  return response
};

export const loginUserService = async ({ email, password }: Pick<User, 'email' | 'password'>) => {
  const response = axios.post(BASE_URL + 'user/jwt/create/', {
    email: email,
    password: password
  })
  return response;
}

export const activateUserAccountService = async ({ uid, token }: activateUserAccountServiceType) => {
  axios.post(BASE_URL + 'user/users/activation/', {
    uid: uid,
    token: token
  })
}

export const loadUserDetails = async () => {
  const response = axios.get(BASE_URL + 'user/users/me/', {
    headers: {
      'Authorization': `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}
export const loadUserDetailsService = withTokenCheck(loadUserDetails)

export const resetPasswordService = async (email: string) => {
  const response = axios.post(BASE_URL + 'user/users/reset_password/', {
    email
  });
  return response;
}

export const resetPasswordConfirmService = async ({ uid, token, newPassword, reNewPassword }: PasswordConfirmType) => {
  const response = axios.post(BASE_URL + 'user/users/reset_password_confirm/', {
    uid: uid,
    token: token,
    new_password: newPassword,
    re_new_password: reNewPassword
  });

  return response;
}

export const uploadProfilePicture = async (file: File) => {
  if (!file) {
    return Promise.reject(new Error("No file provided"));
  }

  const formData = new FormData();
  formData.set("avatar", file);

  const response = await axios.post(BASE_URL + "upload-avatar/", formData, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("access")}`,
      "content-Type": "multipart/form-data",
    },
  });

  return response;
}

export const uploadProfilePictureService = withTokenCheck(uploadProfilePicture)