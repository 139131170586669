import { BASE_URL } from "../common/constants";
import axios from "axios";
import { CategoryType } from "../common/category-model";
import { withTokenCheck } from "./userAxiosServices"

const placeToMarketCategory = async ({
  categoryId,
  categoryName,
  userId,
  description,
  createdBy,
  marketApprovement,
  chosenLanguageRequest,
  categoryType
}: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "description" | "createdBy" | "marketApprovement" | "chosenLanguageRequest" | "categoryType">) => {
  const body = {
    name: categoryName,
    user: userId,
    description: description,
    created_by: createdBy,
    market_approvement: marketApprovement,
    language: chosenLanguageRequest,
    type_of_cards: categoryType

  }
  const response = axios.put(BASE_URL + `card/market/${categoryId}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const placeToMarketCategoryService = withTokenCheck(placeToMarketCategory)

const removeFromMarketCategory = async ({
  categoryId,
  categoryName,
  userId,
  description,
  createdBy,
  marketAccessible,
  marketApprovement,
  chosenLanguageRequest,
  categoryType
}: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "description" | "createdBy" | "marketAccessible" | "marketApprovement" | "chosenLanguageRequest" | "categoryType">) => {
  const body = {
    name: categoryName,
    user: userId,
    description: description,
    created_by: createdBy,
    market_accessible: marketAccessible,
    market_approvement: marketApprovement,
    language: chosenLanguageRequest,
    type_of_cards: categoryType
  }
  const response = axios.put(BASE_URL + `card/market/${categoryId}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const removeFromMarketCategoryService = withTokenCheck(removeFromMarketCategory)

export interface MarketRequestParams {
  searchQuery?: string;
  mostDownload: boolean;
  lastUpdate: boolean;
  topRate: boolean
  currentPage: number;
  chosenLanguageRequest: string
  categoryTopicRequest: string
}

const getMarketCategories = async ({
  searchQuery, 
  mostDownload,
  lastUpdate,
  topRate,
  currentPage,
  chosenLanguageRequest,
  categoryTopicRequest
}: MarketRequestParams) => {
  const response = axios.get(BASE_URL + `card/market/`, {
    params: {
      page: currentPage,
      search_value: searchQuery,
      most_download: mostDownload,
      last_update: lastUpdate,
      top_rate: topRate,
      language: chosenLanguageRequest,
      topic: categoryTopicRequest
    },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getMarketCategoriesService = withTokenCheck(getMarketCategories)

export const getPublicMarketCategoriesService = async ({
  searchQuery, 
  mostDownload,
  lastUpdate,
  topRate,
  currentPage,
  chosenLanguageRequest,
  categoryTopicRequest
}: MarketRequestParams) => {
  //const csrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content');

  const response = axios.get(BASE_URL + `card/market-public/`, {
    params: {
      page: currentPage,
      search_value: searchQuery,
      most_download: mostDownload,
      last_update: lastUpdate,
      top_rate: topRate,
      language: chosenLanguageRequest,
      topic: categoryTopicRequest
    },
    withCredentials: true,
    // headers: {
    //   "X-CSRFToken": csrfToken
    // }
    });
  return response;
}

export interface AdobeCategoryFromMarket {
  categoryId: string
  userId: string
}
const adoptCategoryFromMarket = async ({categoryId, userId}: AdobeCategoryFromMarket) => {
  const body = {category_id: categoryId, user_id: userId}
  const response = axios.post(BASE_URL + `card/adopted-from-market/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });

  return response;
}

export const adoptCategoryFromMarketService = withTokenCheck(adoptCategoryFromMarket)


export interface getPublicCategoryDetails {
  categoryId: string
}

export const getPublicCategoryFromMarketService = async ({ categoryId }: getPublicCategoryDetails) => {
  const response = axios.get(BASE_URL + `card/market-public/${categoryId}/`);
  return response;
}
